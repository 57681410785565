import React from "react"
import styled from "styled-components"
import Layout from "../components/layout"
import {
  SEOTitle,
  Wrapper,
  Container,
  Row,
  Column,
  Subtitle,
  Text,
} from "../theme/index"
import { FaFacebook, FaInstagram, FaLinkedin, FaTwitter } from "react-icons/fa"
import { Colors, Responsive } from "../theme/styles"
import { ContactForm, Seo } from "../components/index"

const StyledLink = styled.a`
  ${Responsive.sm`
cursor: pointer;
color: ${Colors.darkBlue};
margin: ${props => (props.margin ? "0px 15px 0 0" : "0")};
&:hover {
  color: ${Colors.lightBlue};
}
`}
  ${Responsive.lg`
margin-top: 0px;
`}
`

const Contact = () => {
  return (
    <Layout title="Contact Us" type="contact">
      <Seo
        title="Contact CheshTech Digital Agency"
        description="Are you looking for ways to optimize your website so it generates you more leads and makes you money? Contact CheshTech today for a free consultation!"
      />
      <Wrapper white>
        <Container>
          <Column>
            <SEOTitle margin="0 0 5px 0">
              Have any questions? Contact us today!
            </SEOTitle>
            <Subtitle small>
              Fill out the form below, shoot us an email, give us a call, or DM
              us on social media
            </Subtitle>
            <Text margin="5px 0 5px 0" black>
              Email: info@cheshtech.com
            </Text>
            <Text black>Phone: (206) 569-8628</Text>
            <Row
              justifycontent="center"
              alignitems="center"
              margin="15px 0 30px 0"
            >
              <StyledLink margin href="https://facebook.com/CheshTech">
                <FaFacebook size={40} />
              </StyledLink>
              <StyledLink margin href="https://instagram.com/CheshTech">
                <FaInstagram size={40} />
              </StyledLink>
              <StyledLink margin href="https://twitter.com/CheshTech_">
                <FaTwitter size={40} />
              </StyledLink>
              <StyledLink href="https://www.linkedin.com/company/cheshtech/">
                <FaLinkedin size={40} />
              </StyledLink>
            </Row>
            <ContactForm />
          </Column>
        </Container>
      </Wrapper>
    </Layout>
  )
}

export default Contact
